if (module.hot) {
    module.hot.accept();
}

import 'modernizr';
import 'main/webpack-config';
import 'util/fixes';
import { installPolyfills, handleErrors } from 'util/polyfills';


// /**
//  * Main entry point to the js app. Javascript execution starts here
//  * 1. Webpack config and general fixes are initialized via `import` statement
//  * 2. Polyfills are imported and asynchronously processed via `Promise` API
//  * 3. In case of success, the vue app is initialized
//  */
installPolyfills
    .then(() => require.ensure([], () => require('framework/base'), null, 'app-boot')) // eslint-disable-line global-require
    .then(app => app.init())
    .then(() => require.ensure([], () => require('util/firebase'), null, 'firebase-boot')) // eslint-disable-line global-require
    .then(({ init: initFirebase }) => initFirebase())
    .catch(handleErrors);
