/**
 * Fixes wrong viewport behaviour on ieMobile.
 * This function depends on further css styling, that should be present on the site:
 * @-webkit-viewport   { width: device-width; }
 * @-moz-viewport      { width: device-width; }
 * @-ms-viewport       { width: device-width; }
 * @-o-viewport        { width: device-width; }
 * @viewport           { width: device-width; }
 */
(function ieMobileFix() {
    if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
        var msViewportStyle = document.createElement('style');
        msViewportStyle.appendChild(
            document.createTextNode('@-ms-viewport{width:auto!important}')
        );
        document.getElementsByTagName('head')[0].appendChild(msViewportStyle);
    }
}());
